:root {
	font-size: 14px;
	--main_color: #583985;
	--sec_color: #ffffff;
}

body {
	min-height: 100svh;
	margin: 0;
}
